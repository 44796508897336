// footer Cookie Policy
const btnAccept = document.getElementById('btnAcceptAll');
const btnDecline = document.getElementById('btnDeclineAll');
const cookies = document.getElementById('cookies');

window.addEventListener('load', () => {
  debugger;
  if (getCookie('ctx') == false) {
    cookies.classList.add('show');
    //   cookies.style.display = 'block';
  } else {
    cookies.classList.remove('show');
    //   cookies.style.display = 'none';
  }
});

btnAccept.addEventListener('click', (e) => {
  setCookies();
  e.preventDefault();
});
btnDecline.addEventListener('click', (e) => {
  cookies.style.display = 'none';
  e.preventDefault();
});

function setCookies() {
  cookies.style.display = 'none';
  setCookie('ctx', 'true', 1);
}
function setCookie(name, value, days) {
  var expires = '';
  if (days) {
    var date = new Date();
    // date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}
function getCookie(name) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return false;
}
